import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../layouts";
import HeroGeneral from "../components/heroes/general";
import AddressBlock from "../components/address-details";
import PortableText from "../components/portable-text";

const Page = ({ data }) => (
  <Layout title={`${data.page.title}`}>
    <HeroGeneral title={data.page.title} />

    <div className="font-standard flex flex-col md:flex-row max-w-screen-desktop mb-8 md:mb-16 mx-auto px-6">
      <div className="md:w-3/4 mb-8 md:mb-0 md:mr-20 text-xl">
        <PortableText blocks={data.page._rawBody} />
      </div>
      <div className="font-standard md:w-1/4 mb-10 md:mb-0">
        <AddressBlock />
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query($id: String) {
    page: sanityPage(id: { eq: $id }) {
      title
      _rawBody
      slug {
        current
      }
    }
  }
`;

Page.propTypes = {
  data: PropTypes.shape.isRequired,
};

export default Page;
